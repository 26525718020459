// Constants
export const SMALL_PAGE_WIDTH = 600;
export const ULTRA_SMALL_PAGE_WIDTH = 350;

// The type of data to include for each project
export type ProjectInfo = {
	title: string;
	subtitle: string;
	techs: string;
	body: string[];
	media: {
		filename: string;
		type: 'image' | 'video';
	};
	buttons: {
		image: string;
		link: string;
	}[];
};

// The data for each project to display in the gallery
export const projects: ProjectInfo[] = [
	{
		title: 'Tuple Tunes',
		subtitle: 'Team (with Brandon Lei, Irene Huang, Janette Park, Joyce Yuan)',
		techs: 'Arduino, C++, Python, MySQL',
		body: [
			'A turn-based game of group music composition that supports discrete notes across three octaves and multiple playback options. Programmed on ESP32 microcontrollers for frontend with a server backend to save completed pieces to a database.',
			'Created for the Intro EECS via Interconnected Embedded Systems (6.08) class.'
		],
		media: {
			filename: 'projects/tupletunes.mp4',
			type: 'video'
		},
		buttons: [
			{ image: 'video.svg', link: 'https://youtu.be/lWnzaUi9fGc' },
			{ image: 'github.svg', link: 'https://github.com/jacksluong/tuple-tunes' }
		]
	},
	{
		title: 'Campfire',
		subtitle: 'Team (with Brandon Lei and Tony Cui)',
		techs: 'NodeJS, MongoDB, React, TypeScript',
		body: [
			'A web-based, real-time multiplayer game where you and others construct a story together in hot-potato style, limited only by your own creativity. Contributions include building the backend with game logic for concurrent rooms via websockets.',
			"Created for MIT web.lab 2021 and awarded the Staff's Choice Award."
		],
		media: {
			filename: 'projects/campfire.mp4',
			type: 'video'
		},
		buttons: [
			{ image: 'external.svg', link: 'https://erifpmac.herokuapp.com' },
			{ image: 'github.svg', link: 'https://github.com/jacksluong/campfire' }
		]
	},
	{
		title: 'Boardy',
		subtitle: 'Individual',
		techs: 'Swift',
		body: [
			'A bulletin board iOS app for my high school that allows students to conveniently share and find school-related information with features such as filtering, pinning, and grouping postings by category or student orgs. (Currently not in use.)'
		],
		media: {
			filename: 'projects/boardy.mp4',
			type: 'video'
		},
		buttons: [{ image: 'github.svg', link: 'https://github.com/jacksluong/boardy/tree/main' }]
	},
	{
		title: 'Circus',
		subtitle: 'Individual',
		techs: 'Java, JavaFX',
		body: [
			'An imitation employee database focused on supporting sort, add, remove, and search operations efficiently with a user interface.'
		],
		media: {
			filename: 'projects/circus.mp4',
			type: 'video'
		},
		buttons: [{ image: 'github.svg', link: 'https://github.com/jacksluong/circus' }]
	},
	{
		title: 'Botball',
		subtitle: 'Team (with Malden High School Robotics Team)',
		techs: 'C++',
		body: [
			'Two self-built autonomous robots programmed to perform a multitude of tasks in a miniature environment that resembles a real-world scenario, competing against other robots to earn points in a shared playing field. The challenge was to come up with the best solution to handle the aftermath of a destructive storm in a city. Contributions include guiding strategy formation and delegating programming responsibilities.',
			'Created for Botball Robotics 2019 and won the regional championship (qualifying for internationals).'
		],
		media: {
			filename: 'projects/botball.mp4',
			type: 'video'
		},
		buttons: [
			{ image: 'external.svg', link: 'https://www.kipr.org/botball/what-is-botball' },
			{ image: 'github.svg', link: 'https://github.com/jacksluong/botball-2019' }
		]
	},
	{
		title: 'Towers of Hanoi (GUI)',
		subtitle: 'Individual',
		techs: 'Java, JavaFX',
		body: [
			'A GUI that animates a solution of the Towers of Hanoi problem with playback controls and settings (e.g. number of pieces, dark mode).'
		],
		media: {
			filename: 'projects/towersofhanoi.mp4',
			type: 'video'
		},
		buttons: [{ image: 'github.svg', link: 'https://github.com/jacksluong/java-minis' }]
	}
];

// The data about classes, grouped by semester
export const semesters: { title: string; classes: string[] }[] = [
	{
		title: 'Spring 2023',
		classes: [
			'6.5840 (6.824) - Distributed Computer Systems Engineering',
			'6.1800 (6.033) - Computer Systems Engineering',
			'CMS.301 - Game Design Methods'
		]
	},
	{
		title: 'Fall 2022',
		classes: [
			'6.1060 (6.172) - Software Performance Engineering',
			'21G.049 - French Photography',
			'7.012 - Introduction to Biology'
		]
	},
	{
		title: 'Spring 2022',
		classes: [
			'6.1910 (6.004) - Computation Structures',
			'6.1220 (6.046) - Design and Analysis of Algorithms',
			'5.111 - Principles of Chemical Science'
		]
	},
	{
		title: 'Fall 2021',
		classes: [
			'6.1020 (6.031) - Elements of Software Construction',
			'6.3900 (6.036) - Introduction to Machine Learning',
			'6.UAT - Oral Communication'
		]
	},
	{
		title: 'Spring 2021',
		classes: [
			'6.1210 (6.006) - Introduction to Algorithms',
			'6.1010 (6.009) - Fundamentals of Programming',
			'6.9010 (6.08) - Intro to EECS via Interconnected Embedded Systems',
			'CMS.590 - Design and Development of Games for Learning'
		]
	},
	{
		title: 'Fall 2020',
		classes: [
			'6.1200 (6.042) - Mathematics for Computer Science',
			'8.02 - Physics II: Electricity and Magnetism',
			'9.01 - Introduction to Neuroscience',
			'CMS.100 - Introduction to Media Studies'
		]
	}
];

// Generates pseudo-random keys to shuffle array indices with
const baseStrings =
`eater of PB&J omelettes,competitive Spotify player,pineapple jalapeño pizza advocate,
programming lover,nerdy joke generator,Supercell games supporter,hidden voice actor,
soccer player,tennis player,photography enthusiast,ping pong player,an older brother,
follower of Jesus,skateboarder,piano player,guitar learner,
Wingspan fan,Catan amateur,curious learner,extraorganized person,movie watcher,MCU aficionado,
Pokémon nerd,egg chef,snail,character builder,veteran ISFJ-A,user of no phone case,
enjoyer of Italian food,glass-three-quarters-full optimist,experimenter,
seeker of challenges,logistics handler,fan of minimalist design`
    .replaceAll('\n', '')
    .split(',');
const MULTIPLIER = Math.ceil(Math.random() * 100);
const keyGenerator = (index: number): number => ((index + 1) * MULTIPLIER) % baseStrings.length;

// The pool of words for the typewriter animation in the About page
// NOTE: Make sure to end each line with a comma
const indices = Array.from(Array<number>(baseStrings.length).keys(), keyGenerator);
export const descriptorStrings = indices.map(index => baseStrings[index]);
